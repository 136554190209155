<template>
  <div class="first-step">
    <div class="flex items-center justify-between border-0 border-b border-solid border-grey-light">
      <p class="font-medium sm:text-4xl text-3xl text-black leading-none mb-4">{{$t('Dashboard')}} </p>
      <shipment :user="user" :tour="tour" :tourClosed="tourClosed" @tour="tour = $event"></shipment>
    </div>
    <valu-banner class="mt-3" />
    <profile-progress v-if="!profileCompleted" :profileCompletionSteps="profileCompletionSteps" :profileCompletionLoading="profileCompletionLoading" class="mt-3" />
    <div class="mt-3 grid xl:grid-cols-3 lg:grid-cols-5 grid-cols-1 gap-3">
      <div class="xl:col-span-2 lg:col-span-3 col-span-1">
        <pricing-section />
        <activities-section class="lg:hidden block mt-3"/>
        <statistics-section class="mt-3" />
      </div>
      <div class="xl:col-span-1 lg:col-span-2 lg:block hidden">
        <activities-section class="h-full"/>
      </div>
    </div>
  </div>
</template>

<script>
import Shipment from './components/Shipment.vue'
import ValuBanner from './dashboard/ValuBanner.vue'
import ProfileProgress from './dashboard/ProfileProgress.vue'
import PricingSection from './dashboard/Pricing.vue'
import ActivitiesSection from './dashboard/Activities.vue'
import StatisticsSection from './dashboard/Statistics.vue'
import common  from '@/assets/utils/common'
import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'
import { sendRequest } from '@/http/axios/requestHelper'

export default {
  data () {
    return {
      userMerchant: {},
      tour:'',
      tourClosed: true,
      user: {},
      profileCompletionSteps: [],
      profileCompletionLoading: false,
      profileCompleted: true
    }
  },
  methods:{
    addTourStep (element) {
      if (element.hideNext) {
        this.tour.addStep({
          text: element.text,
          attachTo: {
            element: element.element,
            on: 'bottom'
          },
          buttons: [
            {
              action: async () => {
                if (element.next) {
                  this.tour.back()
                } else {
                  this.tourClosed = false
                  this.tour.cancel()
                }
              },
              classes: 'shepherd-button-secondary',
              text: element.button1
            }
          ]
        })
      } else {
        this.tour.addStep({
          text: element.text,
          attachTo: {
            element: element.element,
            on: 'bottom'
          },
          buttons: [
            {
              action: async () => {
                if (element.next) {
                  this.tour.back()
                } else {
                  this.tourClosed = false
                  this.tour.cancel()
                }
              },
              classes: 'shepherd-button-secondary',
              text: element.button1
            },
            {
              action: async () => {
                this.tour.next()
              },
              text: element.button2
            }
          ]
        })
      }
    },
    loadProfileCompletion () {
      this.profileCompletionLoading = true
      sendRequest(true, false, this, 'api/v2/analytics/merchant/dashboard/profile-completion/', 'get', null, true,
        (response) => {
          this.profileCompletionSteps = response.data
          this.profileCompletionLoading = false
          this.profileCompleted = this.profileCompletionSteps.filter(item => !item.complete).length === 0
        }
      )
    }
  },
  components: {
    Shipment,
    ValuBanner,
    ProfileProgress,
    PricingSection,
    ActivitiesSection,
    StatisticsSection,
    Shepherd
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    let isHaveFinanceAccess = ''
    merchantData.then(results => {
      this.userMerchant = results.userMerchantData
      this.user = results.merchantData
      isHaveFinanceAccess = this.userMerchant.access_financials
      this.merchantID = this.user.id
      if (this.$cookies.get('tourMerchantDashboard') === 'false') {
        this.tour = new Shepherd.Tour({
          useModalOverlay: true,
          defaultStepOptions: {
            exitOnEsc: true,
            classes: 'shadow-md bg-purple-dark',
            scrollTo: {behavior: 'smooth', block: 'center'}
          }
        })
        const setpsArray = [
          {
            text: this.$t('Your dashboard gives you quick insights about your logistics needs.'),
            element: '.first-step',
            button1: this.$t('Cancel'),
            button2: this.$t('Next'),
            next: false
          },
          {
            text: this.$t('Click here to create a new delivery order.'),
            element: '.step-selector',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true,
            hideNext: true
          },
          {
            text: this.$t('Click here to create a new delivery order.'),
            element: '.step-selector-2',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true,
            hideNext: true
          },
          {
            text: this.$t('Now, add customer details below'),
            element: '.step-selector-3',
            button1: this.$t('Cancel'),
            button2: this.$t('Next'),
            next: false
          },
          {
            text: this.$t('Great! Now, select the number of packages and optionally add description and cash on delivery amount.'),
            element: '.step-selector-4',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true
          },
          {
            text: this.$t('Optionally, add a reference number for the order and leave any note for our delivery agent.'),
            element: '.step-selector-5',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true
          },
          {
            text: this.$t('Your are now one-step ahead from creating your first order. Click Create Order now!'),
            element: '.step-selector-6',
            button1: this.$t('Back'),
            button2: this.$t('Done!'),
            next: true,
            hideNext: true
          }
        ]
        if (!isHaveFinanceAccess) setpsArray.splice(9, 1)
        setpsArray.forEach((element) => { 
          this.addTourStep(element)
        })
      }
      if (this.$cookies.get('tourMerchantDashboard') === 'false' && !(this.user.is_self_signup && this.user.self_signup_verified === null && this.user.name === 'Entrepreneur Merchant')) {
        this.tour.start()
        this.$cookies.set('tourMerchantDashboard', true, '100y')
      }
    })
    this.loadProfileCompletion()
  }
}
</script>

<style lang="scss">
.shepherd-enabled.shepherd-element {
  z-index: 51900;
}
.shepherd-modal-is-visible{
  z-index: 51900;
}
.shepherd-text {
  padding: 0 2em;
}
.shepherd-header {
  padding: 0 0.75rem;
  height: 35px;
}
.shepherd-text{
  padding: 1rem 0.5rem 0rem 0.5rem;
}
.shepherd-footer{
  margin-top: 16px;
}
.shepherd-button {
  background: #1C5BFE;
}
.shepherd-arrow:before {
  background: #f3f3f3;
}
.shepherd-element{
  background: #f3f3f3;
}
.shepherd-button {
  color: #fff; 
}
[dir] .shepherd-button.shepherd-button-secondary {
  background: #fff;
}
.tracking-widest {
  letter-spacing: 0.15em;
}
.material-symbols-rounded.fill {
  font-variation-settings: 'FILL' 1
}
.card-loader {
  position: relative;
  border-radius: 5px;
  height: 170px;
  overflow: hidden;
  &:before {
    content: '';
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  }
  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    animation: loader-animate 1s infinite linear;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}
@keyframes loader-animate{
  0% {transform: translate3d(-100%, 0, 0);}
  100% {transform: translate3d(100%, 0, 0);}
}
</style>