<template>
  <div>
    <div class="bg-white border border-solid border-grey-light rounded-lg sm:p-7 p-5">
      <div v-if="profileCompletionLoading" class="card-loader"></div>
      <template v-else>
        <div class="flex md:flex-row flex-col gap-y-10 justify-between relative">
          <div v-for="(step, index) in profileCompletionSteps" :key="index" class="text-center flex md:flex-col items-center gap-2">
            <div class="progress-line absolute bg-grey-light border border-solid border-grey-light"></div>
            <span :class="`material-symbols-rounded fill text-3xl flex-shrink-0 z-10 ${(step.complete || inProgressStep(step)) ? 'text-primary' : 'text-grey-light'}`">{{ step.complete ? 'check_circle' : 'circle' }}</span>
            <p :class="`px-1 ${step.complete ? '' : 'underline cursor-pointer'} ${(step.complete || inProgressStep(step)) ? 'text-black' : 'text-grey cursor-text'}`" @click="inProgressStep(step) ? completeStep(step.check) : ''">{{ $t(step.check) }}</p>
          </div>
        </div>
        <p class="flex items-end gap-1 mt-10">
          <feather-icon icon="FlagIcon" svgClasses="w-4 h-4 text-primary mt-1"></feather-icon>
          <span @click="startTour()" class="text-sm border-0 border-b border-solid leading-none cursor-pointer">{{ $t('Need help to get started? Take a tour now') }}</span>
        </p>
      </template>
    </div>
    <add-merchant-profile-info :addMerchantInfo="addMerchantInfo" @addMerchantInfo="addMerchantInfo = $event" />
  </div>
</template>

<script>
import AddMerchantProfileInfo from '../components/AddMerchantProfileInfo.vue'

export default {
  props: ['profileCompletionSteps', 'profileCompletionLoading'],
  data () {
    return {
      addMerchantInfo: false
    }
  },
  methods: {
    completeStep (step) {
      switch (step) {
      case 'profile_complete':
        this.addMerchantInfo = true
        break
      case 'account_type':
        this.$router.push(`/${this.$route.params.lang}/merchant/account?tab=account-details&page=1`).catch(() => {})
        break
      case 'payout_method':
        this.$router.push(`/${this.$route.params.lang}/merchant/account?tab=payout-methods&page=1`).catch(() => {})
        break
      case 'first_order':
        this.$router.push(`/${this.$route.params.lang}/merchant/orders/delivery-orders`).catch(() => {})
        break
      default:
        break
      }
    },
    inProgressStep (step) {
      if (step.check === this.profileCompletionSteps.filter(item => !item.complete)[0].check) {
        return true
      }
    },
    startTour () {
      this.$router.push({
        name: 'merchant-dashboard'
      }).catch(() => {})
      this.$cookies.set('tourMerchantDashboard', false)
      this.$cookies.set('tourMerchantOrders', false)
      this.$cookies.set('reloaded', false)
      if (this.$route.name === 'merchant-dashboard') {
        location.reload()
      }
    }
  },
  components: {
    AddMerchantProfileInfo
  }
}
</script>

<style lang="scss">
[dir=ltr] .progress-line {
  top: 13px;
  left: 3.5em;
  width: calc(100% - 8.5em);
}
[dir=rtl] .progress-line {
  top: 13px;
  right: 4em;
  width: calc(100% - 8.5em);
}
@media (max-width: 768px) {
  [dir=ltr] .progress-line {
    top: 13px;
    left: 12px;
    height: calc(100% - 26px);
    width: 1px;
  }
  [dir=rtl] .progress-line {
    top: 13px;
    right: 12px;
    height: calc(100% - 26px);
    width: 1px;
  }
}
</style>