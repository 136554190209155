<template>
  <div class="bg-white border border-solid border-grey-light rounded-lg sm:p-7 p-5">
    <div v-if="cardLoading" class="card-loader"></div>
    <template v-else>
      <p :class="`text-sm opacity-75 ${$i18n.locale === 'en' ? 'tracking-widest' : ''}`">{{ $t('PRICING') }}</p>
      <p v-if="$i18n.locale === 'en'" class="mt-3 text-lg text-black">{{ pricingData.remaining_orders + ' ' + $t('orders away to get') + ' ' + pricingData.upcoming_discount + '% ' + $t('discount!') }}</p>
      <p v-else class="mt-3 text-lg text-black">{{ $t('discount!') + ' ' + pricingData.remaining_orders + ' ' + $t('orders away to get') + ' ' + pricingData.upcoming_discount + '% ' }}</p>
      <div class="mt-2 border border-solid border-grey-light rounded-full h-4">
        <div class="progress-bar h-full bg-primary rounded-full" :data="pricingData.current_orders_count + $t(' orders')" :style="`--progress-width: ${(pricingData.current_orders_count / (pricingData.current_orders_count + pricingData.remaining_orders)) * 100}%;`"></div>
      </div>
      <div class="mt-2 flex items-center justify-between sm:text-sm text-xs text-black">
        <p>{{ $t('Base Price: ') + pricingData.price_start + ' ' + $t('EGP') }}</p>
        <p>{{ $t('Discounted Price: ') + pricingData.price_end + ' ' + $t('EGP') }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'

export default {
  data () {
    return {
      pricingData: {
        remaining_orders: 0,
        upcoming_discount: 0,
        current_orders_count: 0,
        price_start: 0,
        price_end: 0
      },
      cardLoading: false
    }
  },
  methods: {
    loadPricingData () {
      this.cardLoading = true
      sendRequest(true, false, this, 'api/v2/analytics/merchant/dashboard/pricing/', 'get', null, true,
        (response) => {
          this.pricingData = response.data
          this.cardLoading = false
        }, (error) => {
          this.cardLoading = false
          common.notifyResponseError(this, error)
        }
      )
    }
  },
  created () {
    this.loadPricingData()
  }
}
</script>

<style lang="scss">
.progress-bar {
  width: 0px;
  position: relative;
  animation: grow 0.5s linear forwards;
  &:hover {
    &::after {
      content: attr(data);
      position: absolute;
      top: -32px;
      right: 0;
      background: #fff;
      border: 1px solid #DEDEDE;
      border-radius: 14px;
      font-size: 12px;
      color: #656565;
      padding: 3px 8px;
    }
    &::before {      
      content: '';
      position: absolute;
      top: -11px;
      right: 29px;
      background: #fff;
      border-bottom: 1px solid #DEDEDE;
      border-right: 1px solid #DEDEDE;
      width: 8px;
      height: 8px;
      z-index: 1;
      transform: rotate(45deg);
    }
  }
}
@keyframes grow {
  to {
    width: var(--progress-width);
  }
}
</style>